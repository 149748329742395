import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from '../components/layout';
import SEO from '../components/seo';
import PostListing from '../components/PostListing';
import config from '../../data/SiteConfig';

const CategoryPageTemplate = ({ pageContext, data }) => {
  
    const { category } = pageContext;
    const { edges, totalCount } = data.allMarkdownRemark;
    const categoriesLabel = {
        tech: "Technology",
        products: "Products",
        entertainment: "Entertainment"
    };
    const post = totalCount > 1 ? 'posts' : 'post';

    return (
      <DefaultLayout>
        <SEO
            title={category.charAt(0).toUpperCase() + category.slice(1)}
            description={config.siteTitle}
            keywords={[`gobinda`, `category`, `blogs`, `thakur`, categoriesLabel[category]]}
        />
        <div className="pages">
            <h3>{`${totalCount} ${post} in category "${categoriesLabel[category]}"`}</h3>
        </div>
        <PostListing posts={edges} />
      </DefaultLayout>
    );
}

export default CategoryPageTemplate;

export const pageQuery = graphql`
  query CategoryPageTemplate($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
